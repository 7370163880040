import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    user: {},
    role: "migrant",
  },
  getters: {
    user(state) {
      return state.user;
    },
    role(state) {
      return state.role;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setRole(state, role) {
      state.role = role;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setRole({ commit }, role) {
      commit("setRole", role);
    },
  },
  modules: {},
});
