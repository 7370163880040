import { createRouter, createWebHistory } from 'vue-router';
import Auth from '../views/Auth.vue';
import store from '../store/index';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Auth
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue')
  },
  {
    path: '/register/migrant',
    name: 'RegistrationMigrant',
    component: () => import(/* webpackChunkName: "register_migrant" */ '../views/registration/Migrant.vue')
  },
  {
    path: '/register/lawyer',
    name: 'RegistrationLawyer',
    component: () => import(/* webpackChunkName: "register_lawyer" */ '../views/registration/Lawyer.vue')
  },
  {
    path: '/recovery/init',
    name: 'RecoveryInit',
    component: () => import(/* webpackChunkName: "recovery_init" */ '../views/recovery/Init.vue')
  },
  {
    path: '/recovery/process/',
    name: 'RecoveryProcess',
    component: () => import(/* webpackChunkName: "recovery_process" */ '../views/recovery/Process.vue'),
    props: (route) => ({ token: route.query.token })
  },
  {
    path: '/register/confirm/',
    name: 'RegisterConfirmation',
    component: () => import(/* webpackChunkName: "registration_confirm" */ '../views/registration/Confirm.vue'),
    props: (route) => ({ token: route.query.token })
  },
  {
    path: '/profile',
    name: 'ProfileMain',
    meta: {
      requiresLogin: true
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "profile_personal" */ '../views/profile/Personal.vue'),
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "profile_settings" */ '../views/profile/Settings.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "profile_settings" */ '../views/profile/settings/Common.vue'),
          },
          {
            path: 'notifications',
            component: () => import(/* webpackChunkName: "profile_settings" */ '../views/profile/settings/Notifications.vue'),
          },
          {
            path: 'security',
            component: () => import(/* webpackChunkName: "profile_settings" */ '../views/profile/settings/Security.vue'),
          },
          {
            path: 'subscriptions',
            component: () => import(/* webpackChunkName: "profile_settings" */ '../views/profile/settings/Subscriptions.vue'),
          }
        ]
      },
      {
        path: 'pay-info',
        component: () => import(/* webpackChunkName: "profile_payinfo" */ '../views/profile/PayInfo.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "profile_payinfo" */ '../views/profile/payment/Common.vue'),
          },
          {
            path: 'balance',
            component: () => import(/* webpackChunkName: "profile_payinfo" */ '../views/profile/payment/Balance.vue'),
          },
          {
            path: 'history',
            component: () => import(/* webpackChunkName: "profile_payinfo" */ '../views/profile/payment/History.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue')
  },
  {
    path: '/сontacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue')
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    meta: {
      requiresLogin: true
    },
    component: () => import(/* webpackChunkName: "marketplace" */ '../views/marketplace/Main.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "marketplace" */ '../views/marketplace/Browser.vue'),
      },
      {
        path: 'create-task',
        component: () => import(/* webpackChunkName: "marketplace" */ '../views/marketplace/CreateTask.vue'),
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin) && !store.state.user.id) {
    next({
      path: "/auth"
    });
  } else {
    next();
  }
})

export default router;
