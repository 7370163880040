<template>
  <div class="header-authorization">
    <div class="container">
      <div class="header-authorization__body">
        <a href="/">
          <picture>
            <source srcset="/img/logo.svg" type="image/webp" />
            <img
              src="/img/logo.svg"
              alt="logo"
              class="header-authorization__logo"
            />
          </picture>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnAuthHeader",
};
</script>