<template>
  <footer class="footer">
    <div class="footer__flex container">
      <div class="footer__flex-column-1">
        <router-link to="/team" class="footer__flex-link">Команда</router-link>
        <a href="#" class="footer__flex-link">FAQ</a>
        <router-link to="/сontacts" class="footer__flex-link">Контакты</router-link>
      </div>
      <div class="footer__flex-column-2">
        <a href="#" class="footer__flex-link medal" @click="toggleRole">Стать {{ roleText }}</a>
        <a href="#" class="footer__flex-link mail">Написать в поддержку</a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "Footer",
  computed: {
    ...mapGetters([
      'role',
    ]),
    roleText() {
      return this.role === 'migrant' ? 'специалистом' : 'участником';
    }
  },
  methods: {
    ...mapActions([
      'setRole'
    ]),
    toggleRole() {
      this.setRole(this.role === 'migrant' ? 'lawyer' : 'migrant');
    }
  }
};
</script>