export class API {
  static URL = "/api/?alef_action=";

  static dataToFormData(data) {
    const form_data = new FormData();
    for (const key in data) {
      form_data.append(key, data[key]);
    }

    return form_data;
  }

  static async send(endpoint, data, method) {
    const options = {
      method,
    };

    if (method === "POST") {
      options.body = API.dataToFormData(data);
    } else {
      endpoint += `&${Object.entries(data)
        .map((item) => `${item[0]}=${item[1]}`)
        .join("&")}`;
    }

    const response = await fetch(`${this.URL}${endpoint}`, options);

    return await response.json();
  }
}
