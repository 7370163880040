<template>
  <div class="password">
    <p class="text form__text">{{ title }}</p>
    <div class="error">
      <input
        @click="clearInput"
        @input="emitter"
        :type="type"
        class="form__password input"
        v-model="new_value"
        :style="style"
        v-maska="mask"
      />
      <p class="form__email-error" v-show="error">{{ error }}</p>
      <svg
        class="form__password-eye"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="toggleFieldType"
      >
        <path
          d="M21.257 10.962C21.731 11.582 21.731 12.419 21.257 13.038C19.764 14.987 16.182 19 12 19C7.818 19 4.236 14.987 2.743 13.038C2.51238 12.7411 2.3872 12.3759 2.3872 12C2.3872 11.6241 2.51238 11.2589 2.743 10.962C4.236 9.013 7.818 5 12 5C16.182 5 19.764 9.013 21.257 10.962V10.962Z"
          stroke="black"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
          stroke="black"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Password",
  props: ["title", "field", "error", "value", "mask"],
  data() {
    const already_value = this.value ? this.value : '1234567890';
    return {
      new_value: already_value,
      is_clicked: false,
      type: 'password',
      style: {
          font: '400 36px small-caption',
          color: '#c4c4c4'
      }
    };
  },
  methods: {
    clearInput() {
      if (this.is_clicked) return;

      this.is_clicked = true;
      this.new_value = "";
    },
    emitter() {
      this.$emit("value-input", {
          field: this.field,
          value: this.new_value
      });
    },
    toggleFieldType() {
        const was_password = this.type === 'password';

        this.type = was_password ? 'text' : 'password';

        this.style = was_password ? {
            font: '400 14px "Montserrat", sans-serif',
            color: '#000'
        } : {
            font: '400 36px small-caption',
            color: '#c4c4c4'
        }
    }
  },
};
</script>