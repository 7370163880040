<template>
  <div class="wrapper">
    <UnAuthHeader />
    <main class="page">
      <div class="container-615">
        <section class="entity">
          <h1 class="entity__title title">Авторизация</h1>
          <form class="entity__form form" @submit.prevent="sendForm">
            <article class="entity__block">
              <Input
                @value-input="saveValue"
                title="Введите свой email"
                field="email"
                type="email"
                placeholder="test@imigrata.com"
                custom_class="form__email"
                :error="errors.email"
              />
            </article>
            <article class="entity__block">
              <Password
                title="Введите пароль"
                @value-input="saveValue"
                field="password"
                :error="errors.password"
              />
            </article>
            <article>
              <input
                type="submit"
                placeholder=""
                class="form__submit"
                value="Авторизоваться"
              />
            </article>
          </form>
          <div class="social-links">
            <a href="#">
              <picture>
                <source srcset="/img/icon_facebook.svg" type="image/webp" />
                <img src="/img/icon_facebook.svg" alt="facebook" />
              </picture>
            </a>
            <a href="#">
              <picture ref="gauth" id="gauth">
                <source srcset="/img/icon_google.svg" type="image/webp" />
                <img src="/img/icon_google.svg" alt="google" />
              </picture>
            </a>
          </div>
          <div class="forget">
            <div class="entity__already-login">
              <a href="#" @click.prevent="registerByRole" class="entity__already-login-link"
                >Зарегистрироваться</a
              >
            </div>
            <div class="entity__already-login">
              <router-link :to="{ path: '/recovery/init' }" class="entity__already-login-link">
                Забыл пароль?
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { API } from "../helpers/api";
import { Validation } from "../helpers/validation";

import UnAuthHeader from "../components/headers/UnAuth.vue";
import Input from "../components/utils/Input.vue";
import Password from "../components/utils/Password.vue";
import Footer from "../components/Footer.vue";

import { mapGetters, mapActions } from 'vuex';

export default {
  name: "Auth",
  components: {
    UnAuthHeader,
    Input,
    Password,
    Footer,
  },
  data() {
    return {
      values: {
        email: "",
        password: "",
      },
      errors: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters(['role']),
  },
  mounted() {
    const api_lib = document.createElement("script");
    api_lib.src = "https://apis.google.com/js/api:client.js";
    document.head.appendChild(api_lib);

    const attachSignin = (element) => {
      window.auth2.attachClickHandler(
        element,
        {},
        (googleUser) => {
          console.log(googleUser);
        },
        (error) => {
          console.error(error);
        }
      );
    };

    const startGApp = () => {
      if (window.gapi) {
        window.gapi.load("auth2", () => {
          window.auth2 = window.gapi.auth2.init({
            client_id:
              "403258365745-tgqjre6r9ghcla431rgvaj5cc8o7ndan.apps.googleusercontent.com",
            cookiepolicy: "single_host_origin",
            scope: "profile email",
          });
          attachSignin(this.$refs.gauth);
        });
      } else {
        setTimeout(startGApp, 1000);
      }
    };

    startGApp();
  },
  methods: {
    ...mapActions(['setUser']),
    saveValue(data) {
      this.values[data.field] = data.value;
      for (const key in this.errors) {
        this.errors[key] = "";
      }
    },
    async sendForm() {
      this.errors.email = Validation.checkEmail(this.values.email);
      this.errors.password = Validation.checkPassword(this.values.password);

      if (Object.values(this.errors).some((result) => result.length > 0))
        return;

      try {
        const response = await API.send("login", this.values, "POST");

        if (response.status !== 0) {
          this.errors.email = response.user_message;
          return;
        }

        this.setUser(response.user);

        this.$router.push(`/profile`);
      } catch (e) {
        this.errors.email = "Неизвестная ошибка. Попробуйте позже.";
      }
    },
    registerByRole() {
      this.$router.push(`/register/${this.role}`);
    }
  },
};
</script>
