export class Validation {
  static checkEmail(email) {
    return /^\S+@\S+\.\S+$/.test(email) ? '' : 'Неверно указан E-Mail';
  }

  static checkPhone(phone) {
    return /^\+?\d+$/.test(phone) ? '' : 'Неверно указан телефон';
  }

  static checkName(name) {
    if (name.length === 0) {
      return 'Поле пустое';
    }

    return !/(\s|\d)+/.test(name) ? '' : 'Поле содержит недопустимые символы';
  }

  static checkPassword(password) {
    return /^\S+$/.test(password) ? '' : 'Неверно указан пароль';
  }

  static checkUrl(url) {
    if(url.length === 0) {
      return 'Поле не заполнено';
    }

    try {
      const url_obj = new URL(url);

      return url_obj.protocol === "http:" || url_obj.protocol === "https:" ? '' : 'Неверный формат ссылки';
    } catch (_) {
      return 'Неверный формат ссылки';
    }
  }

  static checkText(text) {
    return text.length > 0 ? '' : 'Поле не заполнено';
  }
}
